import React, { useEffect, useRef, useState } from "react";
import styles from "./CreateCollection.module.scss";
import { createCollection, setGenreAndDivision } from "app/helpers/CreateNft";
import ImageIcon from "@mui/icons-material/Image";
import { blue } from "@mui/material/colors";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "app/components/button/button";
import { useHook } from "app/hooks/common";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useHistory } from "react-router-dom";
import { benefits } from "./benefits";
import AddTracksModal from "./AddTracksModal";
import PulseLoader from "react-spinners/PulseLoader";
import PreviewImage from "app/components/PreviewImage/PreviewImage";
import Modal from "app/components/Modal";

function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
export default function CreateCollection() {
  const refSong = useRef(null);
  const refCover = useRef(null);
  const [songFile, setSongFile] = useState(null);
  const [genres, setGenres] = useState(null);
  const [error, setError] = useState(null);
  const [collectionImage, setCollectionImage] = useState(null);
  const [price, setPrice] = useState(null);
  const [collectionName, setCollectionName] = useState(null);
  const [collectionDescription, setCollectionDescription] = useState(null);
  const [collectionImagePreview, setCollectionImagePreview] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [addSong, setAddSong] = useState(false);
  const { cookie } = useHook();
  const status = "draft";
  const [date, setDate] = useState(formatDate(new Date()));
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(null);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const history = useHistory();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState(false);
  const [showInfoPrice, setShowInfoPrice] = useState<boolean>(false);
  const [showInfoTime, setShowInfoTime] = useState<boolean>(false);
  const [showInfoTime2, setShowInfoTime2] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth > 800) {
      document.body.classList.add("no-scroll");
      return () => {
        document.body.classList.remove("no-scroll");
      };
    }
  }, [screenWidth]);

  useEffect(() => {
    if (
      price &&
      status &&
      collectionName &&
      collectionDescription &&
      collectionImage &&
      quantity &&
      startDate &&
      endDate &&
      startTime &&
      endTime
    ) {
      setReady(true);
    }
  }, [
    price,
    status,
    collectionName,
    collectionDescription,
    collectionImage,
    quantity,
    startDate,
    endDate,
    endTime,
    startTime,
  ]);

  /* ====================== */
  /* Get genres of the NFT */
  /* ======== Start ======= */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response, responseGeners] = await setGenreAndDivision();
        setGenres(responseGeners?.data?.genres);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  /* ====================== */
  /*  Get genres of the NFT */
  /* ======== End ======= */

  // check the selected music file for type and size
  const handleMusicFile = (e) => {
    let file = e.target.files[0];
    if (file.type !== "audio/mp3" && file.type !== "audio/mpeg") {
      alert(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 25) {
      alert("The size of the file is to large");
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setSongFile(reader.result);
    };
    reader.readAsDataURL(file);
  };

  // function to store collection image
  const handleNFtImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/webp" &&
      file.type !== "image/gif"
    ) {
      setError(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 5) {
      setError("The size of the file is to large");
      return;
    }
    e.target.value = null;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setCollectionImagePreview(event.target.result);
    };
    setCollectionImage(file);
  };

  /* ====================== */
  /* create the collection */
  /* ======== Start ======= */
  const createCollectionHandler = async () => {
    if (!ready) {
      alert("Please fill in all the fields");
      return;
    }

    const collection = JSON.stringify({
      collection_name: collectionName,
      status: status,
      collection_description: collectionDescription,
      collection_quantity: quantity,
      collection_price: price,
      collection_start_date: startDate,
      collection_end_date: endDate,
      collection_start_time: startTime,
      collection_end_time: endTime,
      collection_type: "music",
    });

    try {
      setLoading((prev) => !prev);
      const create = await createCollection(
        collectionImage,
        cookie,
        collection
      );
      const id = create?.data;
      history.push(`/editCollection/${id}`);

      setLoading((prev) => !prev);
    } catch (error) {
      console.error(error);
      setLoading((prev) => !prev);
    }
  };
  /* ====================== */
  /*  create the collection */
  /* ======== End ======= */

  return (
    <div className={styles.mastering_container}>
      <div className={styles.mastering_container_options}>
        <h1>Create Collection</h1>
        <div className={styles.mastering_container_options_up}>
          <div className={styles.mastering_container_options_left}>
            <div className={styles.info_container}>
              <span>Collection Name</span>
              <div className={styles.album_info}>
                <input
                  type="text"
                  onChange={(e) => setCollectionName(e.target.value)}
                  placeholder=""
                />
              </div>
            </div>
            <div className={styles.info_container}>
              <span>Collection Description</span>
              <div className={styles.album_photo}>
                <textarea
                  onChange={(e) => setCollectionDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className={styles.mastering_container_options_rigth}>
            <div className={styles.info_container}>
              <span>Artwork</span>
              <div
                className={styles.album_photo}
                onClick={() => {
                  refCover.current.click();
                }}
              >
                <ImageIcon sx={{ color: blue[700] }} />
                <input
                  type="file"
                  ref={refCover}
                  hidden
                  onChange={(e) => handleNFtImage(e)}
                  accept="image/jpeg,image/png,image/webp,image/jfif,image/gif"
                />
                {collectionImage ? (
                  <span>Image selected</span>
                ) : (
                  <span>No image selected</span>
                )}
                <span>(796 x 1058px)</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.mastering_container_options_bottom}>
          <div className={styles.mastering_container_options_bottom_up}>
            <div className={styles.info_container}>
              <span>Total Quantity</span>
              <div className={styles.album_info}>
                <input
                  type="number"
                  onChange={(e) => setQuantity(e.target.value)}
                  placeholder="0"
                />
              </div>
            </div>
            <div className={styles.info_container}>
              <div className={styles.price_info}>
                <span>Price</span>
                <InfoOutlinedIcon
                  onMouseLeave={() => setShowInfoPrice(false)}
                  onMouseEnter={() => setShowInfoPrice(true)}
                />
              </div>
              {showInfoPrice && (
                <div className={styles.price_tooltip}>
                  Overall Platform fee is 12% including payment process fess.
                </div>
              )}
              <div className={styles.album_info}>
                <input
                  type="number"
                  onChange={(e) => setPrice(e.target.value)}
                  value={price}
                  placeholder="$0"
                />
              </div>
            </div>
          </div>
          <div className={styles.mastering_container_options_bottom_bottom}>
            <div className={styles.info_container}>
              <span>Start Date</span>
              <div className={styles.album_info}>
                <input
                  aria-label="Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  max="18:00"
                />
              </div>
            </div>
            <div className={styles.info_container}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <span>Start Time</span>
                <InfoOutlinedIcon
                  onMouseLeave={() => setShowInfoTime(false)}
                  onMouseEnter={() => setShowInfoTime(true)}
                />
              </div>
              {showInfoTime && (
                <div className={styles.price_tooltip}>
                  Note: All times are shown in (UTC) time. Please convert your
                  desired start and end times to UTC time in order to ensure
                  exact timing for your release.
                </div>
              )}
              <div className={styles.album_info}>
                <input
                  aria-label="Start Time"
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  max="23:59"
                />
              </div>
            </div>
            <div className={styles.info_container}>
              <span>End Date</span>
              <div className={styles.album_info}>
                <input
                  aria-label="Date"
                  type="date"
                  onChange={(e) => setEndDate(e.target.value)}
                  min={formatDate(new Date())}
                />
              </div>
            </div>
            <div className={styles.info_container}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <span>End time</span>
                <InfoOutlinedIcon
                  onMouseLeave={() => setShowInfoTime2(false)}
                  onMouseEnter={() => setShowInfoTime2(true)}
                />
              </div>
              {showInfoTime2 && (
                <div className={styles.price_tooltip2}>
                  Note: All times are shown in (UTC) time. Please convert your
                  desired start and end times to UTC time in order to ensure
                  exact timing for your release.
                </div>
              )}
              <div className={styles.album_info}>
                <input
                  aria-label="End Time"
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  max="23:59"
                />
              </div>
            </div>
          </div>
          <div className={styles.bottom_container}>
            <div
              className={styles.save_and_add}
              onClick={() => {
                if (ready) {
                  createCollectionHandler();
                } else {
                  setShowModal((prev) => !prev);
                }
              }}
            >
              {loading ? (
                <PulseLoader color="#9b83f9" />
              ) : (
                <span>Save & Continue</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.preview_side}>
        <PreviewImage
          image={collectionImagePreview}
          collectionName={collectionName}
        />
        {/* <Button
          size="sm"
          onClick={() => createCollectionHandler()}
          loading={loading}
          disabled={!ready}
        >
          Create Collection
        </Button> */}
      </div>
      {addSong ? (
        <AddTracksModal
          addSong={addSong}
          setAddSong={setAddSong}
          genres={genres}
          songFile={songFile}
          refSong={refSong}
          handleMusicFile={handleMusicFile}
        />
      ) : null}
      {showModal ? (
        <Modal
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          title={""}
          customSubClass="defaultModal"
        >
          <div className="defaultModalContent">
            <div className={styles.input_label}>
              <span>To submit, please fill in all the fields</span>
            </div>

            <div className="defaultModalButtons">
              <Button size="sm" onClick={() => setShowModal(false)}>
                ok
              </Button>
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
}
