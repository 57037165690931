import React, { useCallback, useEffect, useState } from "react";
import styles from "../CreateCollection/CreateCollection.module.scss";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";

import { useHook } from "app/hooks/common";
import useEditFileName from "app/hooks/useEditFileName";
import Button from "app/components/button/button";
import { editCollectionVideo, UploadVideo } from "app/helpers/UploadVideo";

export default function AddVideoStructure({
  setVideoName,
  videoName,
  video,
  setVideo,
  editVideo,
  addVideo,
  fansCollectionId,
  setAddVideo,
  videoCollectionId,
  setEditVideo,
  setFetchTrigger,
  setCollectionBenefits,
}: {
  setVideoName: (value: string) => void;
  videoName: string;
  video: any;
  setVideo: (value: any) => void;
  editVideo: boolean;
  createCollectionVideo?: any;
  addVideo?: boolean;
  fansCollectionId?: string;
  setAddVideo?: any;
  videoCollectionId?: string;
  setEditVideo?: any;
  setFetchTrigger?: any;
  setCollectionBenefits?: any;
}) {
  const [loading, setLoading] = useState(false);
  const { userInfo, cookie, updateUserInfo } = useHook();
  const [ready, setReady] = useState(false);
  const [createNewVideo, setCreateNewVideo] = useState(null);
  const [editingVideo, setEditingVideo] = useState(null);
  const TrackSizeValidator = (file: File) => {
    const maxFileSize = 300 * 1024 * 1024; // 300MB

    if (file.size > maxFileSize) {
      return {
        code: "name-too-large",
        message: `Song size exceeds the maximum allowed size of 300MB.`,
      };
    }
    return null;
  };

  useEffect(() => {
    if (editVideo) {
      const videoToEdit = video?.find(
        (video) => video.id === videoCollectionId
      );
      setEditingVideo(videoToEdit);
    }
  }, [video]);

  useEffect(() => {
    if (editVideo) {
      setReady(!!videoName);
    } else {
      setReady(!!videoName && !!createNewVideo?.length);
    }
  }, [videoName, createNewVideo, editVideo]);

  useEffect(() => {
    if (editVideo) {
      setVideoName(editingVideo?.name);
    }
  }, [editingVideo]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newVideo = acceptedFiles.map((file) => ({
        file,
        preview: URL?.createObjectURL(file),
      }));
      setCreateNewVideo(newVideo); // Since only one video is allowed, we overwrite the state
    },
    [setCreateNewVideo]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: { "video/*": [".mp4", ".mov", ".avi", ".mkv", ".quicktime"] },
      maxFiles: 1,
      validator: TrackSizeValidator,
    });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  /* ====================== */
  /* upload video of the Collection */
  /* ======== Start ======= */
  const createCollectionVideo = async () => {
    try {
      setLoading(true);

      const response = await UploadVideo(
        fansCollectionId,
        cookie,
        videoName,
        createNewVideo
      );

      if (response) {
        setCollectionBenefits([]);
        setAddVideo(false);
        setCreateNewVideo(null);
        setVideoName("");
        setFetchTrigger((prev) => !prev);
      } else {
        // Handle error
        alert("Failed to upload video");
      }
    } catch (error) {
      console.error("Error creating album:", error);
      alert("An error occurred while creating the album");
    } finally {
      setLoading(false);
    }
  };
  /* ====================== */
  /* upload video of the Collection  */
  /* ======== End ======= */

  const handleEdit = async () => {
    try {
      setLoading(true);
      const response = await editCollectionVideo(
        fansCollectionId,
        cookie,
        videoName,
        videoCollectionId
      );
      if (response) {
        setVideoName("");
        setFetchTrigger((prev) => !prev);
        setEditVideo(false);
      } else {
        // Handle error
        alert("Failed to upload video");
      }
    } catch (error) {
      console.error("Error creating album:", error);
      alert("An error occurred while creating the album");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.album_infos}>
      <div className={styles.inputs_album}>
        <div className={styles.input_label}>
          <span>Video Name</span>
          <div className={styles.input_song}>
            <input
              type="text"
              value={videoName}
              onChange={(e) => setVideoName(e?.target?.value)}
            />
          </div>
        </div>
      </div>

      {!editVideo && createNewVideo?.length > 0 ? (
        <div className={styles.video_preview}>
          <div
            className={styles.close_icon}
            onClick={() => setCreateNewVideo([])}
          >
            <CloseIcon />
          </div>
          <video controls width="100%">
            <source
              src={createNewVideo[0]?.preview}
              type={createNewVideo[0]?.file?.type}
            />
            Your browser does not support the video tag.
          </video>
        </div>
      ) : (
        <div className={styles.drag_drop_contianer}>
          {editVideo ? null : (
            <div {...getRootProps()} className={styles.drag_drop}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <div>
                  <p>Drag 'n' drop your video, or click to select file</p>
                  <em>(1 file Max - 300MB max size)</em>
                </div>
              )}
            </div>
          )}

          {fileRejectionItems.length > 0 && (
            <div className={styles.file_rejections}>
              <ul>{fileRejectionItems}</ul>
            </div>
          )}
        </div>
      )}
      <div className="defaultModalButtons">
        <Button
          size="sm"
          onClick={
            addVideo ? createCollectionVideo : editVideo ? handleEdit : null
          }
          disabled={loading ? loading : !ready}
          loading={loading}
        >
          Done
        </Button>
      </div>
    </div>
  );
}
